import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './assets/styles/main.css'
import './i18n'

Sentry.init({
  dsn: (import.meta as any).env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,

  tracePropagationTargets: ['localhost', /^https:\/\/*\.eyesonasset\.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <StrictMode>
    <Router>
      <App />
    </Router>
  </StrictMode>,
)
