import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material'

export const Wrapper = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: theme.breakpoints.values['md'],
    width: '100%',
    borderRadius: '.8rem',
  },
}))

export const CloseIcon = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: 15,
  right: 15,
  color: theme.palette.common.white,
}))

export const Title = styled(DialogTitle)(() => ({}))

export const ActionsWrapper = styled(DialogActions)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  display: 'flex',
  justifyContent: 'end',
}))

export const Content = styled(DialogContent)(({ theme }) => ({
  maxHeight: '60vh',
  padding: theme.spacing(4),
}))
