import { Box, Drawer, IconButton, Divider as MUIDivider, Typography, styled } from '@mui/material'

export const Wrapper = styled(Drawer)<{ collapsed?: string | null }>(
  ({ open, collapsed, theme }) => ({
    '& .MuiDrawer-paper': {
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.short,
      }),
      border: 'none',
      marginBottom: 'inherit',
      left: 0,
      transform: open ? 'translateX(0px)' : `translateX(350px)`,
      width: collapsed ? '110px' : '300px',
      backgroundColor: 'transparent',
      position: 'fixed',
    },
  }),
)

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 20,
  right: 10,
  color: theme.palette.common.white,
}))

interface IEnvironmentMap {
  [index: string]: string
}

export const InternalWrapper = styled(Box)<{ collapsed?: string | null }>(
  ({ theme, collapsed }) => {
    function getSidenavColor(env: string): string {
      const environmentMap: IEnvironmentMap = {
        demo: '#01002b',
        macquarie: '#01002b',
        develop: '#2b1900',
        prod: theme.gradients.dark,
      }

      if (!(env in environmentMap)) {
        return theme.gradients.dark
      }

      return environmentMap[env as keyof IEnvironmentMap]
    }

    return {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
      height: '100%',
      overflowY: 'scroll',
      margin: [theme.spacing(4), theme.spacing(0), theme.spacing(4), theme.spacing(4)].join(' '),
      padding: theme.spacing(4),
      background: getSidenavColor(ENVIRONMENT),
      color: theme.palette.common.white,
      borderRadius: '.8rem',
      '::-webkit-scrollbar': {
        width: collapsed ? '0px' : '8px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        maxHeight: '10px',
      },
    }
  },
)

export const EnvironmentText = styled(Typography)(() => ({
  textAlign: 'center',
  textTransform: 'uppercase',
}))

EnvironmentText.defaultProps = {
  variant: 'body2',
}

export const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
}))

export const Divider = styled(MUIDivider)(({ theme }) => ({
  height: '0.0425rem',
  borderBottom: 'none',
  borderTop: '0px solid rgba(0, 0, 0, 0.08)',
  borderLeft: '0px solid rgba(0, 0, 0, 0.08)',
  borderRight: '0px solid rgba(0, 0, 0, 0.08)',
  backgroundColor: 'transparent',
  background:
    'linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgba(255, 255, 255, 0)) !important',
  opacity: 0.25,
  margin: [theme.spacing(4), 0].join(' '),
}))
