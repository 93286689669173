import {
  Box,
  Chip,
  ChipProps,
  CircularProgress,
  Paper,
  Skeleton,
  Stack,
  styled,
} from '@mui/material'

export const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: '1.6rem',
  width: '100%',
}))

export const Content = styled(Stack)()

export const SelectedOptionsWrapper = styled(Stack)(() => ({
  flexWrap: 'wrap',
}))

export const AvailableOptionsWrapper = styled(Stack)(() => ({
  flexWrap: 'wrap',
}))

export const AddNewOptionsWrapper = styled(Stack)()

export const Item = styled(Chip)<{ selected?: boolean } & ChipProps>(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.main : theme.palette.background.default,
  color: selected ? theme.palette.common.white : 'inherit',
}))

export const ChipSkeleton = styled(Skeleton)(() => ({
  width: 80,
  height: 30,
}))

export const Loading = styled(CircularProgress)(() => ({
  color: 'white',
}))

export const ChipsContainer = styled(Box)(() => ({ width: '100%', overflowY: 'auto' }))
